import { createRouter, createWebHistory } from "vue-router";
const routes = [
  {
    path: "/:pathMatch(.*)*",
    name: "PageNotFound",
    component: () =>
      import(/* webpackChunkName: "404-info" */ "@/views/PageNotFoundView.vue"),
  },
  {
    path: "/",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "index" */ "@/views/HomeView.vue"),
  },
  {
    path: "/all-programs/",
    name: "AllProgram",
    component: () =>
      import(
        /* webpackChunkName: "all-program" */ "@/views/AllProgramView.vue"
      ),
  },

  {
    path: "/:short_slug/:elective_slug",
    name: "elective-detail",
    component: () =>
      import(
        /* webpackChunkName: "elective-detail" */ "@/views/ElectiveDetailView.vue"
      ),
    props: true,
  },
  {
    path: "/blogs/",
    redirect: { name: "BlogsView" },
  },
  {
    path: "/blogs",
    name: "BlogsView",
    component: () =>
      import(/* webpackChunkName: "blog" */ "@/views/BlogListView.vue"),
  },
  {
    path: "/blogs/:slug/",
    name: "BlogView",
    component: () =>
      import(/* webpackChunkName: "blog-detail" */ "@/views/BlogView.vue"),
    props: true,
  },
  {
    path: "/news-media/",
    name: "NewsMedia",
    component: () =>
      import(/* webpackChunkName: "news-media" */ "@/views/NewsMedia.vue"),
  },
  {
    path: "/refund-policy/",
    name: "Refund",
    component: () =>
      import(/* webpackChunkName: "refund" */ "@/views/RefundView.vue"),
  },
  {
    path: "/testimonial/",
    name: "Success",
    component: () =>
      import(/* webpackChunkName: "AllProgram" */ "@/views/SuccessStories.vue"),
  },
  {
    path: "/academics/",
    name: "AcademicView",
    component: () =>
      import(/* webpackChunkName: "academic" */ "@/views/AcademicView.vue"),
  },
  {
    path: "/grievance-redressal/",
    name: "GrievanceRedressal",
    component: () =>
      import(
        /* webpackChunkName: "grievance-redressal" */ "@/views/GrievanceRedressal.vue"
      ),
  },
  {
    path: "/scholarships/",
    name: "ScholarshipView",
    component: () =>
      import(
        /* webpackChunkName: "scholarships" */ "@/views/ScholarshipView.vue"
      ),
  },
  {
    path: "/privacy-policy/",
    name: "PrivacyView",
    component: () =>
      import(
        /* webpackChunkName: "privacy-policy/" */ "@/views/PrivacyView.vue"
      ),
  },
  {
    path: "/mandatory-disclosures/",
    name: "DisclosuresView",
    component: () =>
      import(
        /* webpackChunkName: "privacy-policy/" */ "@/views/DisclosuresView.vue"
      ),
  },
  {
    path: "/notifications/",
    name: "NotificationView",
    component: () =>
      import(
        /* webpackChunkName: "notifications" */ "@/views/NotificationView.vue"
      ),
  },

  {
    path: "/faqs/",
    name: "FaqsView",
    component: () =>
      import(/* webpackChunkName: "faqs" */ "@/views/FaqView.vue"),
  },
  {
    path: "/faq/:slug/",
    name: "FaqInner",
    component: () =>
      import(/* webpackChunkName: "faq-detail" */ "@/views/FaqInner.vue"),
    props: true,
  },
  {
    path: "/contact-us/",
    name: "ContactView",
    component: () =>
      import(/* webpackChunkName: "contact" */ "@/views/ContactView.vue"),
  },

  {
    path: "/enquire/",
    name: "EnqurieView",
    component: () =>
      import(/* webpackChunkName: "enquire" */ "@/views/EnqurieView.vue"),
  },
  {
    path: "/:slug/",
    name: "ProgramView",
    component: () =>
      import(/* webpackChunkName: "program" */ "@/views/ProgramView.vue"),
  },
  {
    path: "/brochure/:short_name/thankyou",
    name: "ProgramthankyouView",
    component: () =>
      import(
        /* webpackChunkName: "program-thankyou" */ "@/views/ProgramThankyou.vue"
      ),
  },
  {
    path: "/technology",
    name: "TechnologyView",
    component: () =>
      import(/* webpackChunkName: "technology" */ "@/views/TechnologyView.vue"),
  },
  {
    path: "/lp/online-mba-degree-program/",
    redirect: "/lp/online-mba-degree/",
  },
  {
    path: "/lp/online-:afterOnline/",
    name: "OnlineMbaFinance",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "lp-mba" */ "@/views/LPDesign/OnlineMbaFinance.vue"
      ),
  },
  {
    path: "/lp/online-:thankyouSlug/thankyou/",
    name: "OnlineMbaFinanceThankyou",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "lp-mba-thankyou" */ "@/views/LPDesign/OnlineMbaFinanceThankyou.vue"
      ),
  },
  {
    path: "/lp/online-degree-programs/",
    name: "OnlineDegree",
    component: () =>
      import(
        /* webpackChunkName: "lp-degree-programs" */ "@/views/LPDesign/OnlineDegree.vue"
      ),
  },
  {
    path: "/lp/online-mba-degree/",
    name: "OnlineMba",
    component: () =>
      import(
        /* webpackChunkName: "lp-mba-degree" */ "@/views/LPDesign/OnlineMba.vue"
      ),
  },
  {
    path: "/thankyou/",
    name: "ThankYou",
    component: () =>
      import(
        /* webpackChunkName: "AllProgram" */ "@/views/LPDesign/ThankYou.vue"
      ),
  },
  {
    path: "/life-at-jo/",
    name: "LifeAt",
    component: () =>
      import(/* webpackChunkName: "life-at-jo" */ "@/views/LifeAtJoView.vue"),
  },
  {
    path: "/careers/",
    name: "CareerView",
    component: () =>
      import(/* webpackChunkName: "careers" */ "@/views/CareerView.vue"),
  },

  {
    path: "/about-us/",
    name: "AboutUs",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/AboutUsView.vue"),
  },
  {
    path: "/lp/:slug/",
    name: "OnlineView",
    props: true,
    component: () =>
      import(/* webpackChunkName: "lp" */ "@/views/LPDesign/OnlineView.vue"),
  },
  {
    path: "/lp/:slug/thankyou/",
    name: "OnlineViewThankyou",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "lp-thankyou" */ "@/views/LPDesign/OnlineViewThankyou.vue"
      ),
  },
  // {
  //   path: "/lp/online/thankyou/",
  //   name: "LpOnlineThankyou",
  //   props: true,
  //   component:LpOnlineThankyou,

  // },
  {
    path: "/lp/online/p2/",
    name: "Onlinep2",
    component: () =>
      import(/* webpackChunkName: "lp-2" */ "@/views/LPDesign/OnlineP2.vue"),
  },
  {
    path: "/lp/online/p2/thankyou/",
    name: "Onlinep2Thankyou",
    component: () =>
      import(
        /* webpackChunkName: "lp-2-thankyou" */ "@/views/LPDesign/OnlineP2Thankyou.vue"
      ),
  },
  {
    path: "/lp/online/p5/",
    name: "Onlinep5",
    component: () =>
      import(
        /* webpackChunkName: "lp-5-thankyou" */ "@/views/LPDesign/OnlineP5.vue"
      ),
  },
  {
    path: "/lp/online/p5/thankyou/",
    name: "Onlinep5Thankyou",
    component: () =>
      import(
        /* webpackChunkName: "lp-5-thankyou" */ "@/views/LPDesign/OnlineP5Thankyou.vue"
      ),
  },
  {
    path: "/lp/online/p10/",
    name: "Onlinep10",
    component: () =>
      import(/* webpackChunkName: "lp-10" */ "@/views/LPDesign/OnlineP10.vue"),
  },
  {
    path: "/lp/online/p10/thankyou/",
    name: "Onlinep10Thankyou",
    component: () =>
      import(
        /* webpackChunkName: "lp-10-thankyou" */ "@/views/LPDesign/OnlineP10Thankyou.vue"
      ),
  },
  {
    path: "/lp/online-degree-program/",
    name: "OnlineProgram",
    component: () =>
      import(
        /* webpackChunkName: "lp-degree-program" */ "@/views/LPDesign/OnlineProgram.vue"
      ),
  },
  {
    path: "/lp/online-degree-program/thankyou/",
    name: "OnlineProgramThankyou",
    component: () =>
      import(
        /* webpackChunkName: "lp-degree-program-thankyou" */ "@/views/LPDesign/OnlineProgramThankyou.vue"
      ),
  },
  {
    path: "/outreach/",
    name: "Outreach",
    component: () =>
      import(
        /* webpackChunkName: "outreach" */ "@/views/LPDesign/OutreachView.vue"
      ),
  },
  {
    path: "/lp/online-degree-courses/thankyou/",
    name: "OnlineDegreeThank",
    component: () =>
      import(
        /* webpackChunkName: "degree-course-thankyou" */ "@/views/LPDesign/OnlineDegreeThank.vue"
      ),
  },
  {
    path: "/lp/online-mba-degree/thankyou/",
    name: "OnlineMbaThank",
    component: () =>
      import(
        /* webpackChunkName: "lp-mba-thankyou" */ "@/views/LPDesign/OnlineMbaThank.vue"
      ),
  },
  {
    path: "/search/",
    name: "SearchView",
    component: () =>
      import(/* webpackChunkName: "AllProgram" */ "@/views/SearchView.vue"),
  },
  {
    path: "/download-whitepaper-document-thank-you/",
    name: "WhitepaperThakyou",
    component: () =>
      import(
        /* webpackChunkName: "WhitepaperThakyou" */ "@/views/MediaThankyou/WhitepaperViewThankyou.vue"
      ),
  },
  {
    path: "/download-ebook-thank-you/",
    name: "EbbokThakyou",
    component: () =>
      import(
        /* webpackChunkName: "EbbokThakyou" */ "@/views/MediaThankyou/EbbokViewThankyou.vue"
      ), 
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 10, behavior: "smooth" };
  },
});

router.afterEach((to, from) => {
  const toDepth = to.path.split("/").length;
  const fromDepth = from.path.split("/").length;
  to.meta.transition = toDepth < fromDepth ? "slide-right" : "slide-left";
});
export default router;
